import actionButtonsPartial from './action_buttons'
import { appendInternalTrackingSearchParams } from '../../utilities/analytics'
import i18n from '../../../../utilities/translation'
import { FeedItemContext } from 'content/src/lib/feed/interfaces'
import { convertToUrl } from 'content/src/assets/utilities'
import {
  getJavaScriptTemplateForLayout,
  isMomentTheme,
  isPersonalizationEnabled,
  isThreeDotEnabled,
  createAttributes
} from './utils'

export const language = document.documentElement.lang
export const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

const isEdge = layout => layout?.includes('edge')
const isListSmall = layout => layout?.includes('list-small')
const isListMedium = layout => layout?.includes('list-medium')
const isTile = layout => layout?.includes('tile')
const isSlideshow = layout => layout?.includes('slideshow')
const isGamesTileLayout = layout => layout?.includes('games-tile')
const isGamesHeroLayout = layout => layout?.includes('games-hero')
const isGamesCarouselLayout = layout => layout?.includes('games-carousel')
const isGamesLayout = layout =>
  isGamesTileLayout(layout) || isGamesHeroLayout(layout) || isGamesCarouselLayout(layout)
const isQuizHeroLayout = layout => layout?.includes('quiz-hero')
const isQuizCarouselLayout = layout => layout?.includes('quiz-carousel')
const isQuizTile = layout => layout?.includes('quiz-tile')
const isQuizLayout = layout =>
  isQuizTile(layout) || isQuizHeroLayout(layout) || isQuizCarouselLayout(layout)

const whichPodcast = layout =>
  layout?.includes('podcast-large') ? 'podcast-large' : 'podcast-small'
const isNotSponsored = (isSponsored, publisherLogo, publisherName) => {
  return !isSponsored && publisherLogo && publisherName
}

export const header = (
  isSponsored,
  publisherDarkLogo,
  publisherLogo,
  publisherName,
  isVideoLink,
  ms_news,
  layout?
) =>
  `${
    !whichPodcast(layout).includes('podcast')
      ? whichPodcast(layout).includes('podcast-large')
        ? `<div class="block-item__body-header">
    <div class="block-item__body-header-light">${i18n('Featured Podcast', language)}</div>
    <div class="block-item__body-header-dark">${i18n('Featured Podcast', language)}</div>
  </div>`
        : `<div class="block-item__body-header">
            <h1 class="block-item__title ${language}"></h1>
          </div>`
      : `<div class="block-item__body-header  ${
          isMomentTheme(app_theme) ? app_theme : ''
        } ${language}">
        ${
          whichPodcast(layout).includes('large')
            ? `    <div class="block-item__body-header-light">${i18n(
                'Featured Podcast',
                language
              )}</div>
        <div class="block-item__body-header-dark">${i18n('Featured Podcast', language)}</div>`
            : `
        `
        }
  <div class="block-item__body-header-light">
  ${
    isNotSponsored(isSponsored, publisherLogo, publisherName)
      ? ms_news
        ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
      <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
        : `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />`
      : `
          <small class="block-item__publisher-name ${language}">
            ${publisherName}
          </small>`
  }
    ${isVideoLink ? `<small>${i18n('Go to Video', language)}</small>` : ''}
  </div>
  <div class="block-item__body-header-dark ${language}">
  ${
    isNotSponsored(isSponsored, publisherLogo, publisherName) && ms_news
      ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
      <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
      : isNotSponsored(isSponsored, publisherDarkLogo, publisherName)
      ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherDarkLogo}" alt="${publisherName} logo" />`
      : `
          <small class="block-item__publisher-name ${language}">
            ${publisherName}
          </small>
          `
  }
    ${isVideoLink ? `<small class="${language}">${i18n('Go to Video', language)}</small>` : ''}
  </div>
</div>`
  }
  
`

export const media = (thumbnailUrl, isVideoLink, duration, layout) =>
  `
  ${
    thumbnailUrl
      ? `
    <div class="block-item__media ${language} ${isVideoLink ? 'block-item__media--video' : ''}">
      <img loading="${
        isSlideshow(layout) ? 'eager' : 'lazy'
      }" class="block-item__thumbnail" src="${thumbnailUrl}" />
      ${isGamesLayout(layout) || isQuizLayout(layout) ? `<div class="block-item__icon"></div>` : ''}
      ${
        duration
          ? `
          <small class="block-item__media-duration ${language}">
            ${duration}
          </small>
        `
          : ''
      }
  
    ${
      isSlideshow(layout)
        ? `<img loading="lazy" class="block-item__thumbnail-hidden" src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="" />
      <img loading="lazy" class="block-item__thumbnail-hidden-previous" src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="" />
      `
        : ''
    }

      </div>
      `
      : ''
  }
`

export const actions = (layout, title, link, contentApiId) => {
  const url = convertToUrl(link)
  const origin = url?.origin || url
  const pathname = url?.pathname || url

  return `
  ${
    isPersonalizationEnabled &&
    isMomentTheme(app_theme) &&
    isThreeDotEnabled(layout)
      ? `
      <div class='block-item__actions'>
        <button id="personalize"
          data-mp-three-dots-button
          class="button button__icon button__icon--personalize svg--dark"
          type="button"
          data-mp-share-title="${title}"
          data-mp-share-link="${link}"
          data-mp-content-api-id="${contentApiId}"
          data-gtm-id="three_dots" aria-label="${i18n('Three Dots', language)}"
          data-modal-custom-trigger-target-personalization='modal--personalize'
          data-gtm-event-type="click-element"
          data-gtm-event-category="Three Dots"
          data-gtm-event-action="${origin}"
          data-gtm-event-label="${pathname}">

          <div
            class="icon-three-dots"
            data-gtm-id="three_dots"
            data-gtm-event-type="click-element"
            data-gtm-event-category="Three Dots"
            data-gtm-event-action="${origin}"
            data-gtm-event-label="${pathname}"></div>
        </button>
      </div>
    `
      : actionButtonsPartial('dark')
  }`
}

export const bodyLogo = (
  ms_news,
  isSponsored,
  publisherLogo,
  publisherName,
  isVideoLink,
  publisherDarkLogo
) => `
  <div class="block-item__body-logo ${language}">
  <div class="block-item__body-logo-light">
  ${
    isNotSponsored(isSponsored, publisherLogo, publisherName)
      ? ms_news
        ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
      <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
        : `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />`
      : `
          <small class="block-item__publisher-name ${language}">
          ${publisherName}
          </small>`
  }
  ${isVideoLink ? `<small>${i18n('Go to Video', language)}</small>` : ''}
  </div>
  <div class="block-item__body-logo-dark ${language}">
  ${
    isNotSponsored(isSponsored, publisherLogo, publisherName) && ms_news
      ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
      <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
      : isNotSponsored(isSponsored, publisherDarkLogo, publisherName)
      ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherDarkLogo}" alt="${publisherName} logo" />`
      : `
          <small class="block-item__publisher-name ${language}">
          ${publisherName}
          </small>
          `
  }
  ${isVideoLink ? `<small class="${language}">${i18n('Go to Video', language)}</small>` : ''}
  </div>
</div>
`

export const footer = (
  title,
  link,
  isSponsored,
  contentTypeText,
  contentType,
  language,
  layout,
  publisherDarkLogo,
  publisherLogo,
  publisherName,
  isVideoLink,
  ms_news,
  contentApiId,
  pub_time
) =>
  `
  <footer class="block-item__footer ${language}">
    ${
      isSponsored
        ? `<small class="block-item__sponsor-text ${language}">${i18n(
            'sponsored',
            language
          )}</small>`
        : contentTypeText || contentType
        ? `<small class="block-item__content-type ${language}">
            ${contentType ? `<span class="icon icon--media-${contentType}"></span>` : ''}
            ${contentTypeText ? `<span>${i18n(contentTypeText, language)}</span>` : ''}
          </small>`
        : ''
    }

    ${
      isMomentTheme(app_theme) && pub_time
        ? `<span class="block-item__timestamp">${pub_time}</span>`
        : ''
    }

    ${
      ((isEdge(layout) || isListSmall(layout) || isListMedium(layout)) &&
        isMomentTheme(app_theme)) ||
      isTile(layout) ||
      isGamesHeroLayout(layout) ||
      isGamesCarouselLayout(layout) ||
      isQuizHeroLayout(layout)
        ? `
    <div class="block-item__body-logo ${language}">
      <div class="block-item__body-logo-light">
      ${
        ms_news && !isSponsored && publisherLogo && publisherName
          ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
          <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
          : `${
              !isSponsored && publisherLogo && publisherName
                ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />`
                : `
              <small class="block-item__publisher-name ${language}">
                ${publisherName}
              </small>`
            }`
      }
        ${isVideoLink ? `<small>${i18n('Go to Video', language)}</small>` : ''}
      </div>
      <div class="block-item__body-logo-dark ${language}">
      ${
        ms_news && !isSponsored && publisherLogo && publisherName
          ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
          <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
          : `${
              !isSponsored && publisherDarkLogo && publisherName
                ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherDarkLogo}" alt="${publisherName} logo" />`
                : `
              <small class="block-item__publisher-name ${language}">
                ${publisherName}
              </small>
              `
            }`
      }
        ${isVideoLink ? `<small class="${language}">${i18n('Go to Video', language)}</small>` : ''}
      </div>
    </div>
  `
        : ''
    }

    ${
      isSlideshow(layout)
        ? `<a class="block-item__footer-outbound-link" href="${link}" data-mp-cct data-mp-external-content-link
    data-gtm-event-category="content_reveal_external_click" data-gtm-event-type="click-element"
    data-gtm-event-action="${link}" data-gtm-event-label="${link}">
    <span class="block-item__footer-text">${i18n('Tap to read', language)}</span></a>
      ${
        isMomentTheme(app_theme)
          ? '<div class="block-item__footer-gradient"></div>'
          : ''
      }
`
        : ''
    }
    
    ${actions(layout, title, link, contentApiId)}
    </footer>
  `

export const style = (layout, thumbnailUrl) => {
  if (layout.includes('small-photocard-flat')) return ''

  return `--bg-image: url(${thumbnailUrl});`
}

const feedItemTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    duration,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    index,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem

  const template = getJavaScriptTemplateForLayout(layout)
  if (template) {
    return template(feedItem)
  }

  return `
    <article
      ${createAttributes(feedItem)}
      ${layout?.includes('carousel') ? `data-gtm-scroll-count=${index}` : ``}
    >
      <a class="block-item__body"
        href="${appendInternalTrackingSearchParams(link)}"
        data-gtm-event-action="${link}" data-gtm-event-label="${link}"
        data-mp-cct>

        ${media(thumbnailUrl, isVideoLink, duration, layout)}

        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news,
          layout
        )}

        <h1 class="block-item__title ${language}">
          <span class="block-item__headline">${title}</span>
          <span class="block-item__subtitle ${language}"></span>
        </h1>
      </a>
      ${footer(
        title,
        link,
        isSponsored,
        contentTypeText,
        contentType,
        language,
        layout,
        publisherDarkLogo,
        publisherLogo,
        publisherName,
        isVideoLink,
        ms_news,
        contentApiId,
        pub_time
      )}
    </article>
  `
}

export default feedItemTemplate
